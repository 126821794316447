.image {
  width: 15vw;
  height: 15vw;
}

.optionsContainer {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
}

.option {
  color: white;
  border-radius: 1.2vw;
  padding: 0.5vw;
  text-align: center;
  overflow: hidden;
  font-size: 1.2vw;
  margin: 0.5vw;
  z-index: 2;
  height: auto;
}