.ant-descriptions-item > span {
  max-width: 100%;
  overflow-wrap: break-word;
}

.userBadge img {
  width: 100%;
  height: 90px;
  object-fit: contain;
  margin-bottom: 8px;
}

.userBadge-off img {
  filter: grayscale(1) opacity(0.4);
}

.userBadge .ub-title {
  font-weight: bold;
}

.userBadge .ub-desc {
  color: #aaa;
}
