
.miniature {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.miniature :global(.ant-card) {
  border-radius: 4px; 
}

.miniature :global(.ant-card-body) {
  padding: 0px;
  text-align: center;
}

.selected {
  border: 2px solid #ED5244;
}
