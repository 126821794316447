label {
  font-weight: 500;
  margin-bottom: 5px;
  display:block;
}

:global(.ant-list-vertical .ant-list-item-content) {
  margin-bottom: 0px;
}

:global(.ant-select) {
  width: 100%;
}

.hiddenOpts {
  background-color: transparent;
  margin-top: 20px;
  
}

.hiddenOpts :global(.ant-collapse-item) {
  border-bottom: none !important;
}

.hiddenOpts :global(.ant-collapse-header) {
  padding-left: 28px !important;
  color: #999 !important;
}

.hiddenOpts :global(.ant-collapse-header) i {
  left: 4px !important;
}

.hiddenOpts :global(.ant-collapse-content-box) {
  padding: 0;
}