.validations :global(.ant-table) {
  overflow: auto;
  margin-bottom: 32px;
  max-height: 200px;
}

.steps {
  margin-bottom: 24px;
}

.validations th, .validations td {
  white-space: nowrap;
}

.validations h2 {
  margin-bottom: 4px;
}

.validations div:first-of-type h2 {
  color: #a4000f;
}

.validations div:nth-of-type(2) h2 {
  color: #d7b600;
}

.validations div:nth-of-type(3) h2 {
  color: #119600;
}

.validations :global(.anticon-exclamation-circle) {
  color: #d7b600;
}

.validations :global(.anticon-close-circle) {
  color: #a4000f;
}

.validations :global(.anticon-check-circle) {
  color: #119600;
}

.summary {
  color: inherit;
}

.summary {
  text-align: center;
}

.summary :global(.anticon-check-circle) {
  color: #119600;
  font-size: 4em;
  margin: 12px;
}

.summary :global(.anticon-close-circle) {
  color: #a4000f;
  font-size: 4em;
  margin: 12px;
}