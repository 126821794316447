.filters {
}

.filters label {
  color: #666;
  font-weight: lighter;
}

.filters :global(.ant-collapse-header) {
  padding-left: 16px !important;
}

.filters :global(.ant-collapse-header .arrow) {
  left: 0px !important;
}

.filters :global(.ant-collapse-content-box) {
  padding: 0px !important;
  margin-right: 2px;
}

:global(.ant-select-dropdown-menu-item-group-title) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.switchCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}