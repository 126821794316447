.container {
  background-color: #3498db;
  padding-top: 4.6vw;
  padding-bottom: 6.45vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  flex:1;
}

.icon {
  width: 15vw;
  height: 15vw;
}

.title {
  color: white;
  font-weight: bold;
  font-size: 2vw;
  text-align: center;
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
}

.text {
  color: white;
  font-size: 1.7vw;
  text-align: center;
}

.separator {
  width: 6vw;
  margin: 2vw;
  border: 0;
  border-top: 0.2vw solid white;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}


