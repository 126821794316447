.ant-layout-sider .logo {
  height: 32px;
  margin: 16px;
  text-align: center;
  overflow: hidden;
}
.ant-layout-sider .logo .imgLogo {
  display: inline-block;
  height: 100%;
  margin-right: 10px;
}
.ant-layout-sider .logo .imgType {
  display: inline-block;
  height: 100%;
}
.ant-layout-sider-collapsed .logo .imgLogo {
  margin-right: 0;
}
.ant-layout-sider-collapsed .logo .imgType {
  display: none;
}
