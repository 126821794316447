.container {
  background-color: #f4a056;
}

.title {
  color: white;
}

.image {
  width: 10vw;
  height: 10vw;
  margin-top: 2vw;
}

.text {
  color: white;
}