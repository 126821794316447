.title {
  color: rgba(0, 21, 41);
  font-weight: bold;
  font-size: 2.5em;
}

.subtitle {
  color: rgb(120, 137, 153);
  font-size: 0.8em;
}

.number {
  font-weight: bold;
  font-size: 3em;
  color: rgba(0, 21, 41);
  margin: 32px 0;
  text-align: center;
}