body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}
body .course-image {
  object-fit: cover;
  height:200px;
}
body .course-placeholder {
  height:200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
body .course-placeholder > i {
  font-size: 3em;
}

body .card-actions {
  position: absolute;
  bottom: 4px;
  right: 12px;
}