.card {
  
}

.card svg {
  width: 100%;
  height: auto;
  fill: #aaa;
  padding: 20px;
  stroke: #aaa;
}

.card:hover svg {
  fill: #ED5244;
}

.card :global(.ant-typography-ellipsis){
  min-height: 42px;
}

.searchBox {
  margin-bottom: 10px;
}