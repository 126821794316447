.container {
  background-color: #222335;
}

.title {
  color: white;
}

.text {
  color: white;
}

.link {
  text-decoration: underline;
}