.top-buttons {
  margin-bottom: 10px;
}

.header {
}

.header h1 {
  font-size: 1.7em;
  float: left;
  margin-top: 12px;
  margin-bottom: 0;
}

.topLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.topLine :global(.ant-calendar-picker) {
  width: 180px !important;
}

.topLine :global(.ant-calendar-picker .ant-calendar-picker-input) {
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  margin: 0;
  height: auto;
}

.filter :global(.ant-calendar-picker-clear) {
  right: -2px;
}

.filter :global(.ant-input):focus, .filter :global(.ant-select-focused .ant-select-selection), .filter :global(.ant-calendar-picker):focus :global(.ant-input) {
  outline: none;
  box-shadow: none;
}

.topLine :global(.ant-calendar-picker .ant-calendar-picker-input) input {
  height: auto;
  font-size: 0.8em;
}

.collapse {
  background-color: transparent;
  border: none;
}

.collapse :global(.ant-collapse-item) {
  border-bottom: none;
}

.collapse :global(.ant-collapse-header) {
  padding-right: 32px !important;
}

.filter {
  border: 1.5px solid #ccc;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 0.9em;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.filter :global(.ant-select) {
  flex:1;
}

.filter :global(.ant-select-selection__rendered) {
  line-height: 22px;
}

.filter :global(.ant-select-selection__rendered) ul {
  height: 1px;
  position: absolute;
}

.filter :global(.ant-select-selection__choice) {
  margin: 0 !important;
  background-color: transparent;
  border: none;
}

.filter :global(.ant-select-selection) {
  background-color: transparent;
  border: none;
  height: auto;
  font-size: 0.9em;
  min-height: auto;
}

.filterTitle {
  font-weight: bold;
}

.moreFiltersLabel {
  font-weight: bold;
  font-size: 0.9em;
  margin-left: 20px;
}

.header :global(.ant-collapse-content-box) {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.header :global(.ant-collapse-content-box) .filter {
  width: 200px;
}

.dd {
  min-width: 200px !important;
}

.indicator {
  margin-top: 20px;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  min-height: 207px;
  box-shadow: 10px 10px 18px 2px rgba(235,235,235,1);
}

.indicator h2 {
  font-size: 1em;
  margin: 0;
}

.indicator p {
  color: rgba(0, 21, 41, 0.5);
  font-size: 0.8em;
}

.indicatorBody {
  padding: 0 32px;
}