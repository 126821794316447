body .tbl-action {
  padding: 0;
}

body .tbl-action>i {
  font-size: 1.2em !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}

body .tbl-action:hover>i {
  color: #ED5244;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.nameAndEmail {
  flex: 1;
  line-height: 120%;
}

.nameAndEmail>div {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.nameAndEmail>div:last-child {
  font-weight: normal;
  color: #999;
}

.striped-rows td,
.striped-rows th,
.striped-rows td:first-child {
  padding-left: 4px !important;
  padding-right: 4px !important;
}