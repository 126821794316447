.phraseContainer {
  margin-top: 2.5vw;
  margin-bottom: 2.5vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display:flex;
}

.helpText {
  text-align: center;
  color: #949494;
  margin: 2vw;
}

.phraseWord {
  font-size: 1.6vw;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  display:flex;
}

.phraseBreakLine {
  flex-basis: 100%;
  width: 0;
  height: 0;
  overflow: hidden;
  display:flex;
}

.optionsContainer {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
}

.option {
  border-radius: 1.2vw;
  padding: 0.5vw;
  text-align: center;
  overflow: hidden;
  font-size: 1.2vw;
  margin: 0.5vw;
  z-index: 10;
  height: auto;
}