@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  --color-primary: #ED5244;
  --color-secondary: #00BCD4;
}

.content-internal {
  padding: 24px;
  background-color: white;
}

.first-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.aspect-ratio-9-16> :first-child {
  width: 100%;
}

.aspect-ratio-9-16>img {
  height: auto;
}

.aspect-ratio-9-16 {
  position: relative;
}

.aspect-ratio-9-16::before {
  content: "";
  display: block;
  padding-bottom: calc(100% / 0.5625);
  /* 0.5625 = 9/16 */
}

.aspect-ratio-9-16> :first-child {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.striped-rows td {
  font-size: 0.95em;
}

.striped-rows tr:hover td {
  background: rgba(0, 21, 41, 0.2) !important;
}

.striped-rows tr:not(:hover) td {
  background-color: white;
}

.striped-rows tr:nth-child(2n):not(:hover) td {
  background-color: #fbfbfb !important;
}

.striped-rows thead th {
  background-color: white;
}

.striped-rows tr td a {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
}