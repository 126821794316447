.index {
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 6vw;
  height: 6vw;
  border-radius: 3vw;
  padding: 0.5vw;
  margin-bottom: 1.5vw;
  overflow: hidden;
  font-size: 2.5vw;
  font-weight: bold;
  text-align: center;
  line-height: 4.5vw;
}

.icon {
  width: 15vw;
  height: 15vw;
}

.text {
  color: white;
  font-weight: bold;
  font-size: 2vw;
  text-align: center;
  margin-top: 1.5vw;
}



