/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.userMenu .menuToggle {
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer;
}
.userMenu .menuToggle:hover {
  background-color: #e6fffe;
}
.userMenu .menuToggle > .avatar {
  margin-right: 8px;
  vertical-align: middle;
}
