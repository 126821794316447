.templateContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 46px;
  padding-bottom: 64.5px;
  padding-left: 15px;
  padding-right: 15px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.templateTitle {
  font-size: 2.2vw;
  font-weight: bold;
  text-align: center;
  margin: 1.5vw;
  background-color: transparent;
  margin-top: 4px;
}

.templateBody {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: center;
  align-self: stretch;
}

.templateBody > :first-child {
  margin-top: auto;
}

.templateBody > :last-child {
  margin-bottom: auto;
}

.templateText {
  margin: 1vw;
  font-size: 1.8vw;
  line-height: 2vw;
  text-align: center;
}

.templateSubText {
  margin: 1vw;
  font-size: 1.5vw;
  line-height: 1.8vw;
  text-align: center;
}

.templateImage {
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.miniature {
  padding: 10px;
}

.miniature .minIndex {
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 15px;
  border: 2px solid white;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  padding: 2px;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  line-height: 21px;
  background-color: white;
}

.miniature .minIcon {
  width: 30px;
  height: 30px;
}

.miniature .minTitle {
  font-size: 0.8rem;
}