@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

.page {
  min-height: 100vh;
  font-family:"Open Sans",sans-serif;
}
.header {
  padding: 20px;
  border-bottom: 1px solid #e7e7e7;
  height: auto;
}

.header h1 {
  font-size: 36px;
  margin-bottom: 0;
}

.content {
  background-color: white;
  padding-top: 30px;
}

.logo {
    position: absolute;
    top: 20px;
    right: 20px;
}

.logo img {
  height: 40px;
}

.footer {
  background-color: white;
}


.table {

}

.tableItem {
  position: relative;
  border-bottom: 1px solid #eee;
  height: 90px;
  margin-bottom: 10px;
  font-family:"Open Sans",sans-serif;
}

.tableItem .pic {
  float: left;
  position: relative;
}

.tableItem .pic img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}

.tableItem .pic .position {
  position: absolute;
  bottom: -5px;
  left: 50%;
  background-color: #00bcd4;
  color: white;
  padding: 3px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 13px;
  font-size: 12px;
  transform: translateX(-50%);
}

.tableItem .points {
  float: right;
  color: #00bcd4;
  font-size: 2rem;
  line-height: 70px;
}

.tableItem .itemBody {
  margin-left: 90px;
}

.tableItem .itemBody h2 {
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.tableItem .itemBody p {
  font-size: 18px;
  color: #aaa;
  margin: 0 0 10px;
}