.image {
  width: 15vw;
  height: 15vw;
}

.optionsContainer {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
}

.option {
  color: white;
  border-radius: 1.2vw;
  padding: 0.5vw;
  text-align: center;
  overflow: hidden;
  font-size: 1.2vw;
  margin: 0.5vw;
  z-index: 2;
  height: auto;
}

.podium {
  position:relative;
  width:100%;
}

.podium > img {
  width: 100%;
}

.spacesContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vw;
  align-items: flex-end;
}

.space {
  background-color: #d6d6d6;
  border-width: 0.3vw;
  border-color: #ccc;
  border-radius: 50%;
  padding: 2vw;
  align-items: center;
  width: 25%;
  height:0;
  padding-bottom: 20%;
  display: flex;
  justify-content: center;
  margin-bottom: 19vw;
}

.space img {
  margin-top: 100%;
  max-width: 100%;
}

.space:first-child {
  margin-bottom: 9.5vw;
}

.space:last-child {
  margin-bottom: 8vw;
}

.helpText {
  text-align: center;
  color: #949494;
  margin: 2vw;
}