.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f0f2f5;
}
.content {
  padding: 32px 0;
  flex: 1;
}

.top {
  text-align: center;
}

.header {
  height: 44px;
  line-height: 44px;
  box-shadow: none !important;
}

.header a {
  text-decoration: none;
}

.logo {
  height: 44px;
  vertical-align: top;
  margin-right: 16px;
}

.desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .app .content {
    padding: 112px 0 24px 0;
  }
}
