/* .separator {
  height: 4px;
}

.separator:not(.muted) {
  width: 100%;
  cursor: pointer;
}

.separator:not(.muted):hover {
  background-color: #ddd;
} */

.newButton :global(.ant-card) {
  border-style: dashed;
  border-color: #ddd;
}

.newButton :global(.ant-card-body) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.newButton:hover :global(.ant-card) {
  color: #ED5244;
  border-color: #ED5244;
}
