.topButtons {
  margin-bottom: 20px;
  text-align: right;
}

.courseImage {
  object-fit: cover;
  height:200px;
}

.coursePlaceholder {
  height:200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}

.coursePlaceholder > i {
  font-size: 3em;
}
