.image {
  width: 12vw;
  height: 12vw;
  margin: 1vw;
}

.text {
  margin: 1vw 3vw;
}

.input {
  flex: 1;
  align-self: stretch;
  background-color: white;
  border-color: #D6D6D6;
  border-width: 0.1vw;
  border-radius: 1vw;
  padding: 1vw;
  font-size: 1.4vw;
  vertical-align: top;
  min-height: 5vw;
}

.input:hover {
  border-color: #D6D6D6;
}