.container {
  width: 368px;
  margin: 0 auto;
}

h2 {
  color: rgba(0, 0, 0, .65);
  margin-bottom: 24px;
}

.alert {
  margin-bottom: 10px;
}

.adminOnly {
  text-align: center;
}

.adminOnly>div {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 8px;
}

.adminOnly>div img {
  max-width: 100%;
}