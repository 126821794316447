/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body .tbl_report {
  margin-bottom: 20px;
}
body .tbl_report .ant-table:not(.ant-table-empty) .ant-table-body {
  height: 240px;
}
body .tbl_report .ant-table-placeholder {
  height: 240px;
}
body .formRight {
  text-align: right;
}
body .top-bar {
  margin-bottom: 20px;
}
body .add-course-btn {
  text-align: right;
  margin-bottom: 7px;
}
body .drag-item {
  border: 1px solid #ddd;
  border-radius: 8px;
}
body .drag-item .badge {
  margin: 8px;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  background-color: #00BCD4;
  padding: 0 6px;
}
body .drag-item .del-icon {
  top: 12px;
  position: absolute;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #00BCD4;
  cursor: pointer;
}

tr.disabled {
  color: #bbb;
}