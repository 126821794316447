.uploader {
  width: 100%;
}

.uploader :global(.ant-upload) {
  width: 100%;
  height: 94px;
}

.uploader :global(.ant-upload) img {
  height: 78px;
}