.tabColors :global(.ant-col), .tabOther :global(.ant-col) {
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.tabImages :global(.ant-col) {
  display: flex;
  flex-direction: column;
  padding: 0 4px;
}

.tabImagesInner {
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.label {
  display: inline-block;
  margin: 0;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.switch {
  margin: 8px;
}

.tabImages .image {
  height: 0;
  border: 1px solid #ddd;
  /* margin: 8px; */
  padding: 8px;
  padding-bottom: calc(100% / 0.5625);
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.tabImages .image > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.tabImages .image img {
  object-fit: contain;
  display: flex;
  width: 100%;
}

.deleteIcon {
  background-color: rgba(0,0,0,0.6);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color:white;
  padding: 4px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor:pointer;
  position: absolute;
  top: 43px;
  right: 8px;
  z-index:2;
}

.tabImages .image :global(.ant-empty) {
  font-size: 1.4em;
  color: #bbb;
  display: flex;
  flex-direction: column;
}

.tabImages .image :global(.ant-empty) :global(.anticon) {
  font-size: 5em;
}

.tabOther input, .tabOther :global(.ant-select) {
  width: auto;
  max-width: 130px;
}

.tabOther :global(.ant-radio-group), .tabOther .inputs {
  flex: 1;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}
