.link {
  text-decoration: underline;
}

.container {
  background-image: url(../../../../../assets/wp-bg.jpg);
  align-items: flex-end;
}

.message {
  background-color: #DCF7C5;
  padding: 8px;
  max-width: 300px;
  border-radius: 8px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.file {
  background-color: rgba(118, 118, 128, 0.12);
  padding: 4px 12px;
  border-radius: 4px;
}

.image img, .image video {
  width: 100%;
  margin-bottom: 8px;
}