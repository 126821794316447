.buttons {
  margin-top: 40px;
  text-align: center;

  > :first-child {
    margin-right: 10px;
  }
}

.desc {
  :global(.ant-descriptions-item-content) {
    width: 90%;
  }
}

.desc1 {
  vertical-align: top;
}
