
.option {
  color: white;
  border-radius: 1vw;
  padding: 1vw;
  text-align: center;
  overflow: hidden;
  font-size: 1.2vw;
  z-index: 2;
  height: auto;
  display:block;
  width: 100%;
  margin-top: 0.5vw !important;
  margin-bottom: 0.5vw !important;
  box-shadow: 0px 3px 1px 0px #d9d9d9;
  white-space: normal;
  text-align: left;
}