.body :global(.ant-list) {
  width: 100%;
  font-size: 1.3vw;
  border-radius: 1vw;
  border: 0.1vw solid #d9d9d9;
}

.body :global(.ant-list .ant-list-item:first-child) {
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}

.body :global(.ant-list .ant-list-item:last-child) {
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
}

.body :global(.ant-list-item) {
  border-bottom: 0.1vw solid #d9d9d9;
  padding-left: 1vw;
  padding-right: 1vw;
  display: flex;
  flex-direction: row;
}

.body :global(.ant-list-item-content) {
  align-items: center;
}

.icon {
  margin-right: 1vw;
  width: 1.7vw;
  height: 1.7vw;
  background-color: #d9d9d9;
  font-size: 1vw;
  line-height: 1.9vw;
}

.icon svg {
  opacity: 0;
}

.selected {
  color: white;
}

.selected .icon {
  background-color: white; 
}

.selected .icon svg {
  opacity: 1;
}

.optionText {
  flex: 1;
}



