.radioContainer {
  display: flex;
  flex: 1;
}

.radio {
  display: flex;
  flex:1;
  flex-direction: column;
  margin: 1vw;
  align-items: center;
  justify-content: stretch;
}

.radio img {
  width: 100%;
  flex:1;
}

.icon {
  margin-right: 1vw;
  width: 1.7vw;
  height: 1.7vw;
  border: 0.1vw solid #d9d9d9;
  border-radius: 50%;
}

.selected {
  color: white;
}

.selected .icon {
  border: 0.1vw solid white;
}

.selected .icon::after {
  content: '';
  display: block;
  background-color: var(--color);
  width: 1vw;
  height: 1vw;
  border-radius: 0.5vw;
  margin: 0.25vw;
  border: none;
}


