.container {
  margin: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container > img {
  height: 40px;
  margin-top: 70px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.innerContainer > h1 {
  margin-bottom: 0;
}

.innerContainer a {
  color: black;
}

.badge {
  max-width: 150px;
  margin-bottom: 36px;
}

.red {
  color: #e23636;
}
